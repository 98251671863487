<template>
  <div id="transfers">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Transportation List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <!-- v-if="filter.WarehouseId!=null && filter.Status==1 && filter.Date!=null " -->
          <el-button v-if="filter.WarehouseId && filter.Status == 1 && filter.Date && filter.AgentId" type="info" class="btn" @click="exportAsn()"><i class="icon-filter-export"></i>Export ASN</el-button>
          <el-button type="info" class="btn" @click="exportFile()"><i class="icon-filter-export"></i>Export</el-button>
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable @clear="filter.SearchTerm = null"></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Estimated Arrival Date Warehouse</div>
            <!-- <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" style="width: 100%" type="daterange" align="right" unlink-panels range-separator="To" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker> -->
            <el-date-picker v-model="filter.Date" type="date" placeholder="Pick a day" format="dd-MM-yyyy"> </el-date-picker>
          </el-col>

          <el-col class="input-container" :sm="8">
            <div class="text">Status</div>
            <el-select v-model="filter.Status" placeholder="Select" clearable @clear="filter.Status = null">
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'TransferStatus').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
                    <el-col class="input-container" :sm="8">
            <div class="text">Warehouse</div>
            <el-select v-model="filter.WarehouseId" placeholder="Select" clearable @clear="filter.WarehouseId = null">
              <el-option v-for="item in warehouseList" :key="item.value" :label="item.Title" :value="item.ID"> </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="36">

          <el-col class="input-container" :sm="8">
            <div class="text">Agent</div>
            <el-select v-model="filter.AgentId" placeholder="Select" clearable @clear="filter.AgentId = null">
              <el-option v-for="item in agentList" :key="item.value" :label="item.Title" :value="item.ID"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Customer</div>
            <el-select v-model="filter.CustomerId" placeholder="Select" clearable @clear="filter.CustomerId = null" filterable>
              <el-option v-for="item in getAllCustomers" :key="item.value" :label="item.CompanyName" :value="item.ID"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="transferList" :data="transferList" style="width: 100%">
        <el-table-column prop="Product.SKU" label="SKU" min-width="60"> </el-table-column>
        <el-table-column prop="Customer.CompanyName" label="Customer" min-width="100"> </el-table-column>
        <el-table-column prop="Product.Title" label="Product Name" min-width="140"> </el-table-column>
        <el-table-column prop="Shipment.Warehouse.Title" label="Warehouse" min-width="80"> </el-table-column>
        <el-table-column prop="Shipment.PalletQuantity" label="Pallet Quantity" min-width="80"> </el-table-column>
        <el-table-column label="Box Quantity" min-width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.Shipment.OddBoxes != null && scope.row.Shipment.OddBoxes.length > 0 "> {{ scope.row.Shipment.BoxQuantity + scope.row.Shipment.OddBoxes.reduce((accumulator,box) =>{ return accumulator +box.BoxQuantity  },0) }}</span>
            <span v-else> {{ scope.row.Shipment.BoxQuantity }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Shipment.Box.PiecesInBox" label="Pieces In Box" min-width="80"> </el-table-column>
        <el-table-column prop="Shipment.ProductQuantity" label="Quantity" min-width="60"> </el-table-column>
        <el-table-column label="Vehicle" min-width="80">
          <template slot-scope="scope">
            <span style="text-transform: uppercase" v-if="scope.row.Shipment.VehicleDetails != null && scope.row.Shipment.VehicleDetails != ''"> {{ scope.row.Shipment.VehicleDetails }}</span>
            <span v-else> No Vehicle yet </span>
          </template>
        </el-table-column>
        <el-table-column label="EAD Warehouse" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.Shipment.EstimatedArrivalDateWarehouse != null">{{ $moment(scope.row.Shipment.EstimatedArrivalDateWarehouse).format("DD-MM-YYYY") }}</span>
            <span v-else> </span>

          </template>
        </el-table-column>
        <el-table-column prop="Status" label="Status" min-width="90">
          <template slot-scope="scope">
            <div class="status" :class="getClassStatus(scope.row.Status)">
              <span v-if="getEnums">{{ getEnumsDisplay("TransferStatus", scope.row.Status) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" min-width="90">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="setItem(scope.row.ID)"><i class="icon-pen-to-square-solid"></i>View</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="view-edit stuck" @click="changeStatusStuck(scope.row.ID)"><i class="icon-ban-solid"></i>Stuck</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog top="5vh" :visible.sync="dialogVisible" width="60%" :show-close="false" :close-on-click-modal="false">
      <addOrDeleteTransfersDialog :form="form" @close="closeAddDialog()" />
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="viewTransfersDialogVisible" width="60%" :show-close="false" :destroy-on-close="true" :close-on-click-modal="false">
      <viewTransfersDialog :id="setId" @close="closeAddDialog()" v-if="viewTransfersDialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import viewTransfersDialog from "../../components/viewTransfersDialog.vue";
export default {
  components: {
    viewTransfersDialog,
  },
  data() {
    return {
      setId: null,
      form: {},
      filterActive: false,
      dialogVisible: false,
      // dateRange: null,
      viewTransfersDialogVisible: false,
    };
  },
  async beforeMount() {
    await Promise.all([this.$store.dispatch("getTransferList", { Page: 1, PageSize: 999999, SearchTerm: null }), this.$store.dispatch("getSuppliersList"), this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null }), this.$store.dispatch("getWarehouseList"), this.$store.dispatch("getAgentList", { PageSize: 999999, SearchTerm: null, Page: 1, GetDeactivated: false })]);
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  computed: {
    transferList() {
      return this.$store.getters.getTransferList;
    },
    totalCount() {
      return this.$store.getters.getTransfersTotalCount;
    },
    filter() {
      return this.$store.getters.getTransfersFilter;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    getAllCustomers() {
      return this.$store.getters.getCustomersList;
    },
    getAllSuppliers() {
      return this.$store.getters.getSuppliersList;
    },
    projectList() {
      return this.$store.getters.getProjectsList;
    },
    agentList() {
      return this.$store.getters.getAgentList;
    },
    warehouseList() {
      return this.$store.getters.getWarehouseList;
    },
  },
  methods: {
    async changeStatusStuck(id) {
      var payload = {
        TransferId: id,
        Status: 2,
      };

      this.$confirm("Are you sure you want to change the status to stuck?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Transfer/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getTransferList");
          }
        })
        .catch(() => {});
    },
    getClassStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "info";
          break;
        case 2:
          res = "danger";
          break;
        case 3:
          res = "warning";
          break;
        case 4:
          res = "warning";
          break;
      }
      return res;
    },
    arrowsRotate() {
      this.$store.dispatch("getTransferList");
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    closeAddDialog() {
      this.dialogVisible = false;
      this.viewTransfersDialogVisible = false;
    },

    setItem(id) {
      this.setId = id;
      this.viewTransfersDialogVisible = true;
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      // this.dateRange = null;
      this.filter.CustomerId = null;
      this.filter.SupplierId = null;
      this.filter.Status = null;
      this.filter.Date = null;
      this.filter.WarehouseId = null;
      this.filter.AgentId = null;
    },
    async exportFile() {
      var label = "Transfers.xlsx";
      var response = await this.$client.post("/Transfer/ExportReports", this.filter, { responseType: "blob" });
      if (response.status) {
        // $message.success(response.data.Data.Message)
        const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    exportAsn() {
      var token = this.$cookies.get("token")
      window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/asn-document.html?filter=${JSON.stringify({ ...this.filter, PageSize: 999999, Page: 1 })}=filtertoken-seperator=${token}`);
    },
    develop() {
      this.$message.info("Bu özellik geliştirme aşamasındadır...");
      return;
    },
  },
  watch: {
    // dateRange: {
    //   deep: true,
    //   handler() {
    //     if (this.dateRange != null && this.dateRange.length > 0) {
    //       this.filter.StartDate = this.dateRange[0];
    //       this.filter.EndDate = this.dateRange[1];
    //     } else {
    //       this.filter.StartDate = null;
    //       this.filter.EndDate = null;
    //       this.dateRange = null;
    //     }
    //   },
    // },
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getTransferList", this.filter);
      },
    },
  },
};
</script>

<style></style>
