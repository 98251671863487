<template>
  <div class="dialog-container" id="transfers">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && form == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">Transportation Details<span class="yellow-dot">.</span></div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <div class="design mini-form">
            <div class="mini-form">
              <el-col :sm="24" class="right-card">
                <div class="inline-form">
                  <el-row>
                    <el-col :span="12">
                      <div class="text"><i class="icon-calendarday"></i>Collection Date</div>
                    </el-col>
                    <el-col :span="12">
                      <el-date-picker format="dd-MM-yyyy" type="date" v-model="form.Shipment.CollectionDate" placeholder="..." prefix-icon="icon-calendarplus" disabled></el-date-picker>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Customs</div>
                    </el-col>
                    <el-col :span="12">
                      <el-date-picker format="dd-MM-yyyy" type="date" v-model="form.Shipment.EstimatedArrivalDateCustoms"  placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text"><i class="icon-calendarday"></i>Estimated Arrival Date Warehouse</div>
                    </el-col>
                    <el-col :span="12">
                      <el-date-picker format="dd-MM-yyyy" type="date" v-model="form.Shipment.EstimatedArrivalDateWarehouse"  placeholder="..." prefix-icon="icon-calendarplus"></el-date-picker>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">
                        <i class="icon-destination-warehouse"></i>
                        <div>Destination Warehouse</div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <el-select v-model="form.Shipment.WarehouseId" placeholder="Select" disabled style="width: 100%">
                        <el-option v-for="(item, index) in getWarehouseList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">
                        <i class="icon-agent"></i>
                        <div>Agent</div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <el-select v-model="form.Shipment.AgentId" placeholder="Select" style="width: 100%" clearable @clear="form.Shipment.AgentId = null">
                        <el-option v-for="(item, index) in agentList.filter((x) => x.IsDeactivated != true)" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text"><i class="icon-truck"></i>Vehicle Details</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input class="vehicle-input" v-model="form.Shipment.VehicleDetails" clearable @clear="form.Shipment.VehicleDetails = null" placeholder="..."> </el-input>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </div>
          </div>

          <div class="line" v-if="user.Role == 10 || user.Role == 3 || user.Role == 5"></div>
          <el-row>
            <div class="title">Shipping Details<span class="yellow-dot">.</span></div>
            <div class="form">
              <el-row class="right-card">
                <div class="inline-form">
                  <el-row>
                    <el-col :span="12">
                      <div class="text">Final Production Quantity</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input disabled :value="form.Shipment.BoxQuantity * form.Shipment.Box.PiecesInBox" placeholder="..."> </el-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">Pallet Quantity</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input v-model="form.Shipment.PalletQuantity" placeholder="..." disabled> </el-input>
                    </el-col>
                  </el-row>
                  <el-row style="align-items: baseline">
                    <el-col :span="12">
                      <div class="text">Pallet Dimensions</div>
                    </el-col>
                    <el-col :span="12" class="dimensions-deactive">
                      <el-col :span="24">
                        <el-input v-model="form.Shipment.PalletDimensions.X" placeholder="..." disabled>
                          <div slot="append">W</div>
                        </el-input>
                      </el-col>

                      <el-col :span="24">
                        <el-input v-model="form.Shipment.PalletDimensions.Y" placeholder="..." disabled>
                          <div slot="append">L</div>
                        </el-input>
                      </el-col>

                      <el-col :span="24">
                        <el-input v-model="form.Shipment.PalletDimensions.Z" placeholder="..." disabled>
                          <div slot="append">H</div>
                        </el-input>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">Box Quantity</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input v-model="form.Shipment.BoxQuantity" placeholder="..." disabled> </el-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">Pieces in Box</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input v-model="form.Shipment.Box.PiecesInBox" placeholder="..." disabled> </el-input>
                    </el-col>
                  </el-row>
                  <div class="inline-form" v-for="(item,index) in form.Shipment.OddBoxes" :key="index + 'oddBoxQuantity'"> 
                    <el-row>
                      <el-col :span="12">
                        <!-- <el-col :span="8" >
                          <div class="icon">
                            <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                          </div>
                        </el-col> -->
                        <div class="text">Odd Box Quantity {{ index + 1 }}</div>
                      </el-col>
                      <el-col :span="12">
                        <el-input  v-model="form.Shipment.OddBoxes[index].BoxQuantity" disabled> </el-input>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <!-- <el-col :span="8" >
                          <div class="icon">
                            <div class="icon-row-trash" style="cursor: pointer;margin-right: 10px;" @click="deleteOddBox(index)"></div>
                          </div>
                        </el-col> -->
                        <div class="text">Pieces in Odd Box {{ index + 1 }}</div>
                      </el-col>
                      <el-col :span="12">
                        <el-input  v-model="form.Shipment.OddBoxes[index].PiecesInBox" disabled> </el-input>
                      </el-col>
                    </el-row>
                  </div>
                  <el-row style="align-items: baseline">
                    <el-col :span="12">
                      <div class="text">Box Dimensions</div>
                    </el-col>
                    <el-col :span="12" class="dimensions-deactive">
                      <el-col :span="24">
                        <el-input v-model="form.Shipment.Box.BoxDimensions.X" placeholder="..." disabled>
                          <div slot="append">W</div>
                        </el-input>
                      </el-col>

                      <el-col :span="24">
                        <el-input v-model="form.Shipment.Box.BoxDimensions.Y" placeholder="..." disabled>
                          <div slot="append">L</div>
                        </el-input>
                      </el-col>

                      <el-col :span="24">
                        <el-input v-model="form.Shipment.Box.BoxDimensions.Z" placeholder="..." disabled>
                          <div slot="append">H</div>
                        </el-input>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">Weight</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input v-model="form.Shipment.Box.Weight" placeholder="..." disabled> </el-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="text">Total Weight</div>
                    </el-col>
                    <el-col :span="12">
                      <el-input placeholder="..." disabled :value="form.Shipment.Box.Weight * form.Shipment.BoxQuantity"> </el-input>
                    </el-col>
                  </el-row>
                </div>
              </el-row>
            </div>
          </el-row>
          <div class="line" v-if="user.Role == 10 || user.Role == 3 || user.Role == 5"></div>
          <!-- <el-row v-if="user.Role == 10 || user.Role == 3 || user.Role == 5">
            <div class="title2">Packing List</div>
            <div class="upload">
              <div class="all-files" v-if="form.Shipment.PackingList.FileName">
                <div class="files">
                  <div class="file">
                    <div class="file-icon">
                      <img :src="getExtension(form.Shipment.PackingList.FileName)" />
                    </div>
                    <div class="filecontent">
                      <div class="file-name">{{ form.Shipment.PackingList.FileName }}</div>
                      <div class="file-size">{{ fileSize(form.Shipment.PackingList.FileSize) }}</div>
                    </div>
                  </div>
                  <div class="icon-status">
                    <i class="icon-check"></i><a :href="form.Shipment.PackingList.Url" target="_blank"><i class="icon-download"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </el-row>
          <div class="line" v-if="user.Role == 10 || user.Role == 3 || user.Role == 5"></div>
          <el-row v-if="user.Role == 10 || user.Role == 3 || user.Role == 5">
            <div class="title2">Supplier Invoice</div>
            <div class="upload">
              <div class="all-files" v-if="form.Shipment.SupplierProforma.FileName">
                <div class="files">
                  <div class="file">
                    <div class="file-icon">
                      <img :src="getExtension(form.Shipment.SupplierProforma.FileName)" />
                    </div>
                    <div class="filecontent">
                      <div class="file-name">{{ form.Shipment.SupplierProforma.FileName }}</div>
                      <div class="file-size">{{ fileSize(form.Shipment.SupplierProforma.FileSize) }}</div>
                    </div>
                  </div>
                  <div class="icon-status">
                    <i class="icon-check"></i><a :href="form.Shipment.SupplierProforma.Url" target="_blank"><i class="icon-download"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </el-row> -->
        </div>
        <div class="line"></div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i>Cancel</el-button>
          <el-button class="btn" type="info" @click="updateTransportation" :loading="loading == 'saveLoading'" :icon="'el-icon-edit'">Save Changes</el-button>
          <el-button class="btn" type="primary" @click="arrivedTransportation" v-if="form.Status == 1"><i class="icon-check-double-solid"></i> Arrived</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: null,
      loading: false,
      timeout: null,
    };
  },
  props: ["id"],
  async created() {
    await this.getOneTransfer();
    await Promise.all([await this.$store.dispatch("getWarehouseList"), await this.$store.dispatch("getAgents"), await this.$store.dispatch("getAgentList")]);
  },
  methods: {
    async updateTransportation() {
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.form.Shipment.VehicleDetails == "") {
          this.form.Shipment.VehicleDetails = null;
        }
        var res = await this.$client.post("/Transfer/Update", { ID: this.form.ID, VehicleDetails: this.form.Shipment.VehicleDetails, AgentId: this.form.Shipment.AgentId,EstimatedArrivalDateCustoms:this.form.Shipment.EstimatedArrivalDateCustoms,
        EstimatedArrivalDateWarehouse:this.form.Shipment.EstimatedArrivalDateWarehouse });
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
        } else {
          this.$message.success(res.data.Message);
          await this.$store.dispatch("getTransferList", { Page: 1, PageSize: 999999, SearchTerm: null });
        }
        this.loading = false;
        this.close();
      }, 500);
    },
    close() {
      this.$emit("close");
    },
    async getOneTransfer() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          var res = await this.$store.dispatch("getOneTransfer", this.id);
        }
        if (res.HasError) {
          this.close();
        } else {
          this.form = res.Data;
        }
        this.loading = false;
      }, 500);
    },
    async arrivedTransportation() {
      var res = await this.$client.post("/Transfer/Arrived", { TransferId: this.form.ID });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getTransferList");
      }

      this.close();
    },
  },
  computed: {
    agentList() {
      return this.$store.getters.getAgentList;
    },
    getWarehouseList() {
      return this.$store.getters.getWarehouseList;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
